/* eslint-disable @typescript-eslint/no-explicit-any */
import Papa from 'papaparse';
import { ChangeEvent, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../hooks/useApiCall';
import { useQuery } from '../../../../../hooks/useQuery';
import companiesService from '../../../../../services/companiesService';
import ExportXlsx from '../../../../../utils/ExportXlsx';
import formatCnpj from '../../../../../utils/formatCnpj';
import formatCpf from '../../../../../utils/formatCpf';
import hasDesiredKeysAndValues from '../../../../../utils/hasDesiredKeysAndValues';
import isCnpjValid from '../../../../../utils/isCnpjValid';
import isCpfvalid from '../../../../../utils/isCpfValid';
import isEmailValid from '../../../../../utils/isEmailValid';
import removeDuplicates from '../../../../../utils/removeDuplicates';
import renderArray from '../../../../../utils/renderArrayWithComa';

interface UseUploadFileInterface {
  loadUsers: (currentPage: number) => void;
  currentPage: number;
  authenticationType: string;
}

interface CsvDataInterface {
  id: string;
  associated_company?: string | null
}

interface CustomParseResult<T> extends Papa.ParseResult<T> {
  data: T[];
}

export default function useUploadFile({
  loadUsers,
  currentPage,
  authenticationType,
}: UseUploadFileInterface) {
  const [isSubmittingFile, setIsSubmittingFile] = useState(false);
  const [csvFileName, setCsvFileName] = useState('');
  const [csvParsed, setCsvParsed] = useState<CsvDataInterface[]>([]);
  const [modalImportCsvShow, setModalImportCsvShow] = useState(false);
  const [csvAction, setCsvAction] = useState('');

  const { apiCall } = useApiCall();
  const navigate = useNavigate();

  const { companyId } = useParams();

  const query = useQuery();
  const viewByAssociatedCompanies = query.has('viewByAssociatedCompanies');

  function parseFile(file: File):Promise<CustomParseResult<any>> {
		return new Promise((resolve, reject) => {
			const parsedData: any[] = [];

			Papa.parse(file, {
				header: true,
				chunkSize: 5000,
				chunk: (results, parser) => {
					const data: Array<any> = results.data;
					const csvParsedWithoutNullValues = data.filter((list) => (!!list.id || !!list.ID || !!list.Id || !!list.iD));
          const removedDuplicatedIds = removeDuplicates(csvParsedWithoutNullValues).map(obj => Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [key.toLowerCase(), value])
          )) as { [k: string]: string }[];

          const desiredHeaders = viewByAssociatedCompanies ? ['id', 'Empresa Associada'] : ['id'];
					const hasDesiredHeadersAndValues = hasDesiredKeysAndValues(removedDuplicatedIds, desiredHeaders);
					if (!hasDesiredHeadersAndValues) {
						reject(new Error('Identificamos que o arquivo enviado não está com os mesmos cabeçalhos do modelo a ser seguido, ou algum campo foi preenchido em branco'));
            toast.error('Identificamos que o arquivo enviado não está com os mesmos cabeçalhos do modelo a ser seguido, ou algum campo foi preenchido em branco');
						parser.abort();
						return;
					}

          const isCpf = authenticationType === 'cpf';
          const isEmail = authenticationType === 'email';

          let finalIds = removedDuplicatedIds;
          if (isCpf) {
            finalIds = removedDuplicatedIds.map((index) => {
              const canBeCnpj = String(index.id).length > 14;
              const id = canBeCnpj ? formatCnpj(String((index.id))) : formatCpf(String((index.id)).padStart(11, '0').toLowerCase());
              return { ...index, id };
            });

            const areAllCpfsOrCnpjsValid = finalIds.every((index) =>(
              isCpfvalid(formatCpf(String(index.id).toLowerCase()))
            || isCnpjValid(formatCnpj(String(index.id).toLowerCase()))));

            const invalidCpfsOrCnpjs = finalIds.filter((index) => (!isCpfvalid(formatCpf(String(index.id).toLowerCase())) && !isCnpjValid(formatCnpj(String(index.id).toLowerCase()))));

            if (!areAllCpfsOrCnpjsValid) {
              finalIds = finalIds.filter((index) => !invalidCpfsOrCnpjs.includes(index));

              if (!finalIds.length) {
                reject(new Error(`Identificamos que um ou mais CPFs ou CNPJs estão inválidos - ${renderArray(invalidCpfsOrCnpjs.map((index) => index.id))}`));
                toast.error('Identificamos que todos os CPFs ou CNPJs do arquivo estão inválidos');
              }

              toast.warn(`Identificamos que um ou mais CPFs ou CNPJs estão inválidos - os válidos serão importados caso prossiga. Os inválidos foram retornados no arquivo xlsx baixado acima (verifique a aba de downloads de seu navegador) ${renderArray(invalidCpfsOrCnpjs.map((index) => index.id))}`, { autoClose: false });

              ExportXlsx({ data: invalidCpfsOrCnpjs.map((x) => ({ id: x.id })), filename: `CPFs inválidos importados` });
            }
          }

          if (isEmail) {
            const areAllEmailsValid = finalIds.every((index) => isEmailValid(index.id));

            if (!areAllEmailsValid) {
              const invalidEmails = finalIds.filter((index) => !isEmailValid(index.id));

              finalIds = finalIds.filter((index) => !invalidEmails.includes(index));

              if (!finalIds.length) {
                reject(new Error(`Identificamos que todos os e-mails são inválidos`));
                toast.error('Identificamos que todos os emails do arquivo estão inválidos');
              }

              toast.warn(`Identificamos que um ou mais emails estão inválidos - os válidos serão importados caso prossiga. Os inválidos foram retornados no arquivo xlsx baixado acima (verifique a aba de downloads de seu navegador) - Se desejar, você pode prosseguir para importar apenas os válidos`);

              ExportXlsx({ data: invalidEmails.map((x) => ({ id: x.id })), filename: `Emails inválidos importados` });
            }
          }

          const mappedArray = finalIds.map((index) => {
            const idKey = Object.keys(index).find(key => key.toLowerCase() === 'id');
            const canBeCnpj = idKey ? String(index[idKey]).length > 14 : false;

            return {
              id: idKey ? (isCpf ? canBeCnpj ? formatCnpj(String(index[idKey]).toLowerCase()) : formatCpf(String(index[idKey]).toLowerCase()) : String(index[idKey]).toLowerCase()) : '',
              associated_company: String(index['Empresa Associada'] || ''),
            };
          });
					parsedData.push(...mappedArray);
				},
				complete: () => {
					const customResult: CustomParseResult<any> = {
						data: parsedData,
						errors: [],
						meta: {
							delimiter: '',
							linebreak: '',
							aborted: false,
							truncated: false,
							cursor: 0,
						},
					};
					resolve(customResult);
				},
				error: (error) => reject(error),
			});
		});
	}

  async function handleFileUpload(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files) {
      const results = await toast.promise(parseFile(files[0]), {
        pending: 'Aguarde, estamos carregando este arquivo',
        success: 'Seu arquivo está válido. Agora basta importá-lo!',
        error: 'Ocorreu um erro ao carregar seu arquivo',
      });
      const resultArray: CsvDataInterface[] = results.data;

      setCsvFileName(files[0].name);
      setCsvParsed(resultArray);
    }
  }

  const handleSubmitFile = useCallback(async () => {
    if (!companyId) {
      toast.error('Não identificamos a empresa através da URL. Por favor, tente novamente.');
      navigate('/companies');
      return;
    }

    await apiCall({
      apiToCall: (
        csvAction === 'add'
          ? companiesService.createMultiUsers
          : companiesService.deleteUser
      ),
      queryParams: { companyId },
      reqBody: JSON.stringify({
        list: csvParsed.map((index) => ({ id: index.id, associated_company: index.associated_company || '' })),
      }),
      onStartLoad: () => {
        setModalImportCsvShow(false);
        setIsSubmittingFile(true);
      },
      onEndLoad: () => setIsSubmittingFile(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          setIsSubmittingFile(false);
          toast.error(csvAction === 'add'
            ? 'Não foi possível importar os colaboradores'
            : 'Não foi possível remover os colaboradores');
          return;
        }
        loadUsers(currentPage);
        setIsSubmittingFile(false);
        toast.success(csvAction === 'add'
          ? 'Colaboradores importados com sucesso'
          : 'Colaboradores removidos com sucesso');
      },
      catchMessage: (csvAction === 'add'
        ? 'Não foi possível importar os colaboradores'
        : 'Não foi possível remover os colaboradores'),
      catchAction: () => setIsSubmittingFile(false),
    });
  }, [companyId, csvParsed, csvAction, apiCall, navigate, loadUsers, currentPage]);

  function handleImportShow() {
    setModalImportCsvShow(true);
  }

  function handleImportModalClose() {
    setModalImportCsvShow(false);
  }

  return {
    isSubmittingFile,
    csvFileName,
    modalImportCsvShow,
    csvAction,
    setCsvAction,
    handleFileUpload,
    handleSubmitFile,
    handleImportShow,
    handleImportModalClose,
  };
}
