import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import Pagination from '../../../../components/Pagination';
import { useAppContext } from '../../../../contexts/auth';
import { GivenClassType, PeriodType } from '../../types';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  pagesQuantity: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  selectedPeriod: PeriodType;
  setDownloadRelatoryModalShow: Dispatch<SetStateAction<boolean>>;
  givenClassesSplited: GivenClassType[][];
}

export default function ListHeader({
  doesListExists,
  hasError,
  // filteredListLength,
  singularLabel,
  pluralLabel,
  pagesQuantity = 0,
  currentPage,
  onPageChange,
  selectedPeriod,
  setDownloadRelatoryModalShow,
  givenClassesSplited,
}: ListHeaderInterface) {
  const { user } = useAppContext();
  const { permission } = user;
  const total = givenClassesSplited?.flat().length || 0;

  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
      {(doesListExists) && (
          <div className="leftAlignment">
            <strong>
            {total || 0}
              {' '}
              {total === 1 ? singularLabel : pluralLabel}
            </strong>
            <Pagination
              pagesQuantity={pagesQuantity}
              handlePageChange={onPageChange}
              currentPage={currentPage}
            />
            ({(currentPage + 1) * 100 - 99}-{(currentPage + 1) * 100 > total ? total : (currentPage + 1) * 100})
          </div>
        )}
        <div className="actionButtons">
          {(doesListExists && permission === 'super') && (
            <>
              <button type="button" onClick={() => setDownloadRelatoryModalShow(true)}>
                Demonstrativos (Período Selecionado)
              </button>
              <Link to={`/givenClasses/allowDownloads?month=${selectedPeriod.month}&year=${selectedPeriod.year}`}>
                Liberação de Downloads (Período Selecionado)
              </Link>
            </>
          )}

          <Link to={`/givenClasses/new?month=${selectedPeriod.month}&year=${selectedPeriod.year}`}>
            Nova Aula (Período Selecionado)
          </Link>
        </div>
      </Header>
    </OpacityAnimation>
  );
}
