import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { MappedPushType, PushFromApi } from "../types";

export default function useCancelPush() {
  const [isLoading, setIsLoading] = useState(false);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [scheduledPushs, setScheduledPushs] = useState<MappedPushType[]>([]);

  const [pushBeingDeleted, setPushBeingDeleted] = useState<MappedPushType>({} as MappedPushType);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  function handleChangeSearchTerm(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
  }

  function handleOpenDeleteModal(push: MappedPushType) {
    setPushBeingDeleted(push);
    setDeleteModalShow(true);
  }

  const filteredScheduledPushs = useMemo(() => (
    scheduledPushs.filter((push) => push.message.toLowerCase().includes(searchTerm.toLowerCase()))
  ), [scheduledPushs, searchTerm]);

  const loadScheduledPushs = useCallback(async () => {
    try {
      setDoesListApiHasError(false);
      setIsLoading(true);

      const options = {
        method: 'GET',
        headers: {
          'User-Agent': 'insomnia/9.2.0',
          Authorization: 'Basic os_v2_app_jxjntjk6cvaabpk2rlydsxkxulwi4zxdwrrevh5wh5letenjjlciggerubwaszrtkursmz3mka5ow6zuoicf2zmbm53gf4cerfxebcq'
        }
      };

      const response = await fetch('https://api.onesignal.com/notifications?app_id=4dd2d9a5-5e15-4000-bd5a-8af0395d57a2&template_id=42af97f3-dc86-4fb1-9c3e-1565f63f973a', options);

      const pushs: PushFromApi = await response.json();

      if (!pushs.notifications || pushs.notifications.length === 0) {
        setScheduledPushs([]);
        return;
      }

      const filteredOnlyScheduledPushs = pushs.notifications.filter((push) => !push.canceled && !push.completed_at);

      if (!filteredOnlyScheduledPushs || filteredOnlyScheduledPushs.length === 0) {
        setScheduledPushs([]);
        return;
      }

      const mappedPushs = filteredOnlyScheduledPushs.map((push) => ({
        id: push.id,
        message: push.contents.en,
      }));

      setScheduledPushs(mappedPushs);
    } catch (error) {
      toast.error('Erro ao carregar notificações agendadas');
      setDoesListApiHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deletePush = useCallback(async (pushBeingDeletedId: string) => {
    try {
      setIsLoading(true);

      const options = {
        method: 'DELETE',
        headers: {
          'User-Agent': 'insomnia/9.2.0',
          Authorization: 'Basic ZDQ3NjhjZDAtYTQ2ZC00NmI0LWI1ZjgtYmFmNmM3ZjljZTQ0'
        }
      };

      const response = await fetch(`https://api.onesignal.com/notifications/${pushBeingDeletedId}?app_id=4dd2d9a5-5e15-4000-bd5a-8af0395d57a2`, options)
      const deletedPush: { success: boolean } = await response.json();

      if (!deletedPush.success) {
        toast.error('Erro ao cancelar notificação. Tente novamente.');
        return;
      }

      setDeleteModalShow(false);
      loadScheduledPushs();
      toast.success('Notificação cancelada com sucesso');
    } catch (error) {
      toast.error('Erro ao cancelar notificação');
    } finally {
      setIsLoading(false);
    }
  }, [loadScheduledPushs]);

  function handleTryAgain() {
    setDoesListApiHasError(false);
    loadScheduledPushs();
  }

  useEffect(() => {
    loadScheduledPushs();
  }, [loadScheduledPushs]);

  return {
    isLoading,
    searchTerm,
    handleChangeSearchTerm,
    scheduledPushs,
    filteredScheduledPushs,
    doesListApiHasError,
    handleOpenDeleteModal,
    pushBeingDeleted,
    setDeleteModalShow,
    deletePush,
    deleteModalShow,
    handleTryAgain,
  }
}
