import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';
import HttpClient from './utils/HttpClient';

interface IBooksService {
  id?: string;
  reqBody?: ReqBodyType;
  active?: boolean;
  category?: string;
  bookId?: string;
  token: string;
  name?: string;
  categoryId?: string;
}

class BooksService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getBooksList = async ({
    token,
  }: IBooksService) => {
    return this.httpClient.get({
      path: '/books',
      token,
    });
  }

  getExternalBooksList = async ({
    token,
  }: IBooksService) => {
    return this.httpClient.get({
      path: '/books/externals',
      token,
    });
  }

  getBookDetails = async ({
    id,
    token,
  }: IBooksService) => {
    return this.httpClient.get({
        path: `/books/${id}`,
        token,
      });
  }

  getUsageReport = async ({
    token,
  }: IBooksService) => {
    return this.httpClient.get({
        path: `/books/usage-report`,
        token,
      });
  }

  updateBook = async ({
    id,
    reqBody,
    token,
  }: IBooksService) => {
    return this.httpClient.put({
      path: `/books/${id}`,
      reqBody,
      token,
    });
  }

  getCategories = async ({ token }: IBooksService) => {
    return this.httpClient.get({
      path: '/books/categories',
      token,
    });
  }

  createBook = async({
    reqBody,
    token,
  }: IBooksService) => {
    return this.httpClient.post({
      path: '/books',
      reqBody,
      token,
    });
  }

  deleteBook = ({
    id,
    reqBody,
    token,
  }: IBooksService) => {
    return this.httpClient.delete({
      path: `/books/${id}`,
      reqBody,
      token,
    });
  }

  updateBookStatus = async ({
    id,
    active,
    token,
  }: IBooksService) => {
    return this.httpClient.patch({
      path: `/books/${id}?active=${active}`,
      token,
    });
  }

  createCategory = async ({
    reqBody,
    token,
    name,
  }: IBooksService) => {
    return this.httpClient.post({
        path: `/books/categories?name=${name}`,
        reqBody,
        token,
      });
  }
updateCategory = async ({
    categoryId,
    name,
    token,
  }: IBooksService) => {
    return this.httpClient.put({
        path: `/books/categories/${categoryId}?name=${name}`,
        token,
      });
  }

  deleteCategory = async ({
    categoryId,
    token,
  }: IBooksService) => {
    return this.httpClient.delete({
        path: `/books/categories/${categoryId}`,
        token,
      });
  }
}

export default new BooksService();
