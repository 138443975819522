export interface AnyObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default function hasDesiredKeysAndValues(arr: AnyObject[], desiredKeys: string[]): boolean {
  const arrLowerCaseKeys = arr.map(obj => Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key.toLowerCase(), value])
  ));

  return arrLowerCaseKeys.every(obj => {
    return desiredKeys.every(key => {
      const lowerCaseKey = key.toLowerCase();
      const exists = Object.keys(obj).map(k => k.toLowerCase()).includes(lowerCaseKey);
      const value = obj[lowerCaseKey];

      return exists && value !== undefined && value !== '' && value !== null;
    });
  });
}
