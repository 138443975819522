/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeEvent } from "react";
import Select from 'react-select';
import { CustomStyle } from '../../../../../components/CustomSelectStyle';
import FormGroup from "../../../../../components/FormGroup";
import Input from "../../../../../components/Input";
import Textarea from "../../../../../components/Textarea";
import { GetErrorMessageByFieldNameType } from "../../../../../hooks/useErrors";
import { BookTypeType, PartnerType, TecTecaApiResponse } from "../../../types";
import { AsideContainer, Group, Container as StyledContainer } from "../styles";

interface IGeneralDataCard {
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType,
  title: string,
  handleTitleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  externalBooksList: TecTecaApiResponse[];
  selectedExternalBook: { value: string, label: string };
  handleSelectedExternalBookChange: ({ value, label }: { value: string, label: string }) => void;
  type: BookTypeType;
  author: string;
  handleAuthorChange: (e: ChangeEvent<HTMLInputElement>) => void;
  description: string;
  handleDescriptionChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  partnersList: PartnerType[];
  partner: PartnerType;
  handlePartnerChange: ({ value, label }: { value: string, label: string }) => void;
  categoriesList: { value: string, label: string }[];
  category: { value: string, label: string };
  handleCategoryChange: ({ value, label }: { value: string, label: string }) => void;
  level: { value: string, label: string };
  levelOptions: { value: string, label: string }[];
  handleLevelChange: ({ value, label }: { value: string, label: string }) => void;
  edit: boolean;
  isbn: string;
  handleIsbnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  extLevel: string;
  extCategory: string;
}

export default function GeneralDataCard({
  getErrorMessageByFieldName,
  type,
  title,
  handleTitleChange,
  externalBooksList,
  selectedExternalBook,
  handleSelectedExternalBookChange,
  author,
  handleAuthorChange,
  description,
  handleDescriptionChange,
  partnersList,
  partner,
  handlePartnerChange,
  categoriesList,
  category,
  handleCategoryChange,
  level,
  levelOptions,
  handleLevelChange,
  edit,
  isbn,
  handleIsbnChange,
  extLevel,
  extCategory
}: IGeneralDataCard) {
  return (
    <StyledContainer>
      <div className="card-title">
        Dados Gerais *
      </div>

      <AsideContainer>
      {type === 'external' && (
          <Group>
            <div className="title">
              <div>
                Livro externo *
              </div>
            </div>
            <Select
              value={{ value: selectedExternalBook?.value, label: selectedExternalBook?.label }}
              options={externalBooksList?.map((book) => ({ value: book.id, label: book.name }))}
              onChange={(opt) => {
                handleSelectedExternalBookChange(opt!);
              }}
              placeholder="Selecione o livro externo"
              styles={CustomStyle}
              classNamePrefix="react-select"
              className="react-select-container"
              isDisabled={edit}
            />
          </Group>
        )}
        <Group>
          <div className="title">
            Título *
          </div>
          <FormGroup error={getErrorMessageByFieldName('title')}>
            <Input
              value={title}
              onChange={handleTitleChange}
              error={getErrorMessageByFieldName('title')}
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Parceiro *
          </div>
          <Select
            value={{ value: partner?.value, label: partner?.label }}
            options={partnersList}
            onChange={(opt) => handlePartnerChange(opt!)}
            placeholder="Selecione um parceiro"
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
        <Group>
          <div className="title">
            Categoria *
            {type === 'external' && extCategory && (<small style={{ fontSize: '11px'}}>(Rec: {extCategory})</small>)}
          </div>
          <Select
            value={{ value: category?.value, label: category?.label }}
            options={categoriesList}
            onChange={(opt) => {
              handleCategoryChange({ value: opt!.value, label: opt!.label });
            }}
            placeholder="Selecione uma categoria"
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
        <Group>
          <div className="title">
            Nível de Leitura *
            {type === 'external' && extLevel && (<small style={{ fontSize: '11px'}}>(Rec: {extLevel})</small>)}
          </div>
          <Select
            value={{ value: level?.value, label: level?.label }}
            options={levelOptions}
            onChange={(opt) => {
              handleLevelChange({ value: opt!.value, label: opt!.label });
            }}
            placeholder="Selecione um nível de leitura"
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            <div>
              Sinopse *
            </div>
          </div>
          <FormGroup error={getErrorMessageByFieldName('description')}>
            <Textarea
              onChange={handleDescriptionChange}
              placeholder="Coloque a sinopse do livro, um breve resumo."
              value={description}
              height={100}
            />
          </FormGroup>
        </Group>

        <Group>
          <div className="title">
            Autor *
          </div>
          <FormGroup error={getErrorMessageByFieldName('author')}>
            <Input
              value={author}
              onChange={handleAuthorChange}
              error={getErrorMessageByFieldName('author')}
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            ISBN *
          </div>
          <FormGroup error={getErrorMessageByFieldName('isbn')}>
            <Input
              value={isbn}
              onChange={handleIsbnChange}
              error={getErrorMessageByFieldName('isbn')}
            />
          </FormGroup>
        </Group>
      </AsideContainer>
    </StyledContainer>
  )
}
