import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UsersList } from '../interfaces/UsersList';
import useAddCollaborator from './functions/useAddCollaborator';
import useDeleteCollaborator from './functions/useDeleteCollaborator';
import useDownloadCanceledsRelatory from './functions/useDownloadCanceledsRelatory';
import useDownloadUsageRelatory from './functions/useDownloadUsageRelatory';
import useDownloadUsers from './functions/useDownloadUsers';
import useLoadUsers from './functions/useLoadUsers';
import usePageSearches from './functions/usePageSearches';
import useUploadFile from './functions/useUploadFile';

type UsersSplitedType = Array<UsersList[]> | [[]];
type FilteredUsersType = UsersList[] | [];

export default function useUsers() {
  const [usersSplited, setUsersSplited] = useState<UsersSplitedType>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState<FilteredUsersType>([]);

  const statusByCode = {
    0: 'Todos',
    1: 'Ativo',
    2: 'Suspenso',
    3: 'Registrado',
  };

  const {
    fullUsers,
    doesListApiHasError,
    isLoadLoading,
    loadUsers,
    associatedCompanies,
    lastUpdate,
    authenticationType,
  } = useLoadUsers({
    setUsersSplited,
    setFilteredUsers,
    setCurrentPage,
  });

  const {
    searchTerm,
    selectedCodStatus,
    setSelectedCodStatus,
    handleChangeSearchTerm,
    handleChangeStatusFilter,
    handlePageChange,
    handleCompanyChange,
    selectedCompany,
    statusOptions,
    selectedStatus,
    handleStatusChange,
  } = usePageSearches({
    fullUsers,
    usersSplited,
    currentPage,
    filteredUsers,
    setUsersSplited,
    setFilteredUsers,
    setCurrentPage,
  });

  const {
    isSubmittingFile,
    csvFileName,
    modalImportCsvShow,
    csvAction,
    setCsvAction,
    handleFileUpload,
    handleSubmitFile,
    handleImportShow,
    handleImportModalClose,
  } = useUploadFile({
    loadUsers,
    currentPage,
    authenticationType,
  });

  const {
    downloadModalShow,
    setDownloadModalShow,
    selectedFilterDownload,
    setSelectedFilterDownload,
    downloadUsers,
  } = useDownloadUsers();

  const { downloadUsageRelatory } = useDownloadUsageRelatory();
  const { downloadCanceledsRelatory } = useDownloadCanceledsRelatory();

  const {
    isDeleting,
    modalDeleteShow,
    setModalDeleteShow,
    userBeingDeleted,
    setUserBeingDeleted,
    deleteCollaborator,
  } = useDeleteCollaborator({
    loadUsers,
    currentPage,
  });

  const {
    isAdding,
    modalCreateShow,
    handleCreateModalShow,
    handleCreateModalClose,
    collaboratorId,
    handleCollaboratorIdChange,
    addCollaborator,
    handleCompanyFormChange,
    selectedCompanyForm,
  } = useAddCollaborator({
    loadUsers,
    currentPage,
    authenticationType,
  });

  const pagesQuantity = useMemo(() => usersSplited?.length, [usersSplited.length]);

  function handleTryAgain() {
    loadUsers();
  }

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const isLoading = (
    isLoadLoading
    || isSubmittingFile
    || isDeleting
    || isAdding
  );

  return {
    isLoading,
    fullUsers,
    searchTerm,
    handleChangeSearchTerm,
    setSelectedCodStatus,
    doesListApiHasError,
    filteredUsers,
    setUserBeingDeleted,
    setModalDeleteShow,
    statusByCode,
    selectedCodStatus,
    handleTryAgain,
    modalImportCsvShow,
    csvFileName,
    handleFileUpload,
    handleSubmitFile,
    modalDeleteShow,
    userBeingDeleted,
    deleteCollaborator,
    modalCreateShow,
    collaboratorId,
    handleChangeStatusFilter,
    addCollaborator,
    handleImportShow,
    handleCreateModalShow,
    handleImportModalClose,
    handleCreateModalClose,
    setCsvAction,
    csvAction,
    pagesQuantity,
    downloadUsers,
    downloadModalShow,
    setDownloadModalShow,
    setSelectedFilterDownload,
    selectedFilterDownload,
    currentPage,
    handlePageChange,
    loadUsers,
    handleCollaboratorIdChange,
    handleCompanyFormChange,
    handleCompanyChange,
    associatedCompanies,
    selectedCompany,
    selectedCompanyForm,
    downloadUsageRelatory,
    lastUpdate,
    authenticationType,
    statusOptions,
    selectedStatus,
    handleStatusChange,
    downloadCanceledsRelatory,
    usersSplited,
  };
}
