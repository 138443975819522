import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import Sidebar from "../../components/Sidebar";
import Transitions from "../../components/Transition";
import DeleteModal from "./components/DeleteModal";
import Filters from "./components/Filters";
import ListHeader from "./components/header";
import List from "./components/list";
import Search from "./components/searchAtPage";
import UpdateStatusModal from "./components/UpdateStatusModal";
import useBooks from "./useBooks";

export default function Books() {
  const {
    isLoading,
    fullBooks,
    categoriesList,
    selectedCategory,
    partnersList,
    selectedPartner,
    selectedStatus,
    handleStatusChange,
    handlePartnerChange,
    handleCategoryChange,
    handleTypeChange,
    selectedType,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    handlePageChange,
    pagesQuantity,
    currentPage,
    filteredBooks,
    handleOpenDeleteModal,
    handleOpenChangeStatusModal,
    bookBeingDeleted,
    setDeleteModalShow,
    deleteBook,
    deleteModalShow,
    bookChangingStatus,
    setChangeStatusModalShow,
    updateBookStatus,
    changeStatusModalShow,
    handleTryAgain,
    handleDownloadExcel,
    handleDownloadUsageReportExcel,
    booksSplited,
  } = useBooks();

  const hasBooks = fullBooks?.length !== 0 && !!fullBooks && !isLoading;
  const filteredListLength = filteredBooks?.length ?? 0;
  const anyResultsByFilterSelection = !filteredBooks && !isLoading && hasBooks;
  const isListEmpty = !doesListApiHasError && (!isLoading && fullBooks.length === 0);

  return (
    <>
      <Sidebar active="Books" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {hasBooks && !isLoading && (
          <>
            <Filters
              categoriesList={categoriesList}
              selectedCategory={selectedCategory}
              partnersList={partnersList}
              selectedPartner={selectedPartner}
              selectedStatus={selectedStatus}
              handleStatusChange={handleStatusChange}
              handlePartnerChange={handlePartnerChange}
              handleCategoryChange={handleCategoryChange}
              handleTypeChange={handleTypeChange}
              selectedType={selectedType}
            />

            <Search
              searchTerm={searchTerm}
              onChangeSearchTerm={handleChangeSearchTerm}
            />
          </>
        )}

        {!isLoading && (
          <ListHeader
            doesListExists={hasBooks}
            hasError={doesListApiHasError}
            filteredListLength={filteredListLength}
            singularLabel="livro"
            pluralLabel="livros"
            onPageChange={handlePageChange}
            pagesQuantity={pagesQuantity}
            currentPage={currentPage}
            handleDownloadExcel={handleDownloadExcel}
            handleDownloadUsageReportExcel={handleDownloadUsageReportExcel}
            booksSplited={booksSplited}
          />
        )}

        {hasBooks && !isLoading && (
          <List
            filteredList={filteredBooks}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenChangeStatusModal={handleOpenChangeStatusModal}
          />
        )}

        <DeleteModal
          bookBeingDeleted={bookBeingDeleted}
          setDeleteModalShow={setDeleteModalShow}
          deleteBook={deleteBook}
          deleteModalShow={deleteModalShow}
        />

        <UpdateStatusModal
          bookChangingStatus={bookChangingStatus}
          setChangeStatusModalShow={setChangeStatusModalShow}
          updateBookStatus={updateBookStatus}
          changeStatusModalShow={changeStatusModalShow}
        />

{doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter os livros.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
)}
          />
        )}

        {anyResultsByFilterSelection && !doesListApiHasError && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Não encontramos nenhum resultado conforme os filtros selecionados.
                Por favor, tente alterar os critérios.
              </>
  )}
          />
        )}

        {isListEmpty && !isLoading && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhum livro cadastrado, clique no botão
                {' '}
                <strong>Novo Livro</strong>
                {' '}
                acima para cadastrar seu(s) primeiro(s)!
              </>
)}
          />
        )}
      </Transitions>
    </>
  )
}
