import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';
import HttpClient from './utils/HttpClient';

interface SettingsServiceInterface {
  token: string;
  reqBody?: ReqBodyType;
}

class SettingsService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getSettings = async ({
    token,
  }: SettingsServiceInterface) => {
    return this.httpClient.get({
      path: '/kiddle-settings',
      token,
    });
  }

  updateSettings = async ({
    token,
    reqBody,
  }: SettingsServiceInterface) => {
    return this.httpClient.put({
        path: `/kiddle-settings`,
        token,
        reqBody,
      });
  }
}

export default new SettingsService();
