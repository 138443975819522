import { Dispatch, SetStateAction } from 'react';
import FilterRadioButton from '../../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../../components/FilterRadioButtonsContainer';

interface ITypeOfBook {
  setIsRedeemable: Dispatch<SetStateAction<boolean>>;
  isRedeemable: boolean;
  edit: boolean;
}

export default function RedeemableBook({
  setIsRedeemable,
  isRedeemable,
  edit,
}: ITypeOfBook) {
  return (
    <FilterRadioButtonsContainer>
      <FilterRadioButton
        onClick={() => setIsRedeemable(true)}
        selected={isRedeemable}
        disabled={edit}
      >
        Resgatável
      </FilterRadioButton>
      <FilterRadioButton
        onClick={() => setIsRedeemable(false)}
        selected={!isRedeemable}
        disabled={edit}
      >
        Não Resgatável
      </FilterRadioButton>
    </FilterRadioButtonsContainer>
  );
}
