import {
  useMemo,
  useState,
} from 'react';
import { MappedPartnerType } from '../types';
import useDeletePartner from './functions/useDeletePartner';
import useDownloadExcel from './functions/useDownloadExcel';
import useLoadPartners from './functions/useLoadPartners';
import usePageSearches from './functions/usePageSearches';
import useShowPartnersActivities from './functions/useShowPartnersActivities';
import useUpdatePartnerStatus from './functions/useUpdatePartnerStatus';
import useUploadImage from './functions/useUploadImage';

export default function useActivities() {
  const [searchTerm, setSearchTerm] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [partnersSplitted, setPartnersSplited] = useState<Array<MappedPartnerType[]>>([]);
  const [filteredPartners, setFilteredPartners] = useState<MappedPartnerType[]>([]);

  const pagesQuantity = useMemo(() => partnersSplitted.length, [partnersSplitted.length]);

  const {
    isLoadLoading,
    fullPartners,
    doesListApiHasError,
    loadPartners,
  } = useLoadPartners({
    setSearchTerm,
    setPartnersSplited,
    setCurrentPage,
    setFilteredPartners,
  });

  const {
    handleStatusChange,
    handleChangeSearchTerm,
    handlePageChange,
    selectedStatus,
  } = usePageSearches({
    fullPartners,
    setPartnersSplited,
    setFilteredPartners,
    setCurrentPage,
    partnersSplitted,
    searchTerm,
    setSearchTerm,
  });

  const {
    isChangingPartnerStatus,
    changeStatusModalShow,
    partnerChangingStatus,
    updatePartnerStatus,
    handleOpenChangeStatusModal,
    setChangeStatusModalShow,
  } = useUpdatePartnerStatus({
    loadPartners,
  });

  const { downloadExcel, downloadRatings } = useDownloadExcel({ fullPartners });

  const {
    isDeletingPartner,
    deleteModalShow,
    setDeleteModalShow,
    partnerBeingDeleted,
    handleOpenDeleteModal,
    deletePartner,
  } = useDeletePartner({
    setSearchTerm,
    loadPartners,
  });

  const {
    partnerActivitiesBeingShow,
    partnerActivitiesModalShow,
    isPartnerActivitiesLoading,
    handleOpenPartnerActivitiesModal,
    setPartnerActivitiesModalShow,
  } = useShowPartnersActivities();

  const {
    isUploadingImage,
    handleOpenUploadImageModal,
    uploadImageModalShow,
    imageFileName,
    handleFileUpload,
    handleSubmitFile,
    handleUploadImageModalClose,
  } = useUploadImage({ loadPartners })

  function handleTryAgain() {
    loadPartners();
  }

  const isLoading = (isDeletingPartner
    || isChangingPartnerStatus
    || isLoadLoading || isUploadingImage);

  return {
    isLoading,
    searchTerm,
    isLoadLoading,
    isPartnerActivitiesLoading,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredPartners,
    handleTryAgain,
    fullPartners,
    pagesQuantity,
    currentPage,
    handlePageChange,
    handleStatusChange,
    selectedStatus,
    deleteModalShow,
    handleOpenDeleteModal,
    setDeleteModalShow,
    deletePartner,
    partnerBeingDeleted,
    partnerActivitiesBeingShow,
    setPartnerActivitiesModalShow,
    partnerActivitiesModalShow,
    handleOpenPartnerActivitiesModal,
    setChangeStatusModalShow,
    updatePartnerStatus,
    changeStatusModalShow,
    partnerChangingStatus,
    handleOpenChangeStatusModal,
    downloadExcel,
    handleOpenUploadImageModal,
    uploadImageModalShow,
    imageFileName,
    handleFileUpload,
    handleSubmitFile,
    handleUploadImageModalClose,
    downloadRatings,
    partnersSplited: partnersSplitted,
  };
}
