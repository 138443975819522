import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../hooks/useApiCall";
import settingsService from "../../../services/settingsService";

interface ISettingsApiResponse {
  success: boolean;
  settings: {
    id: string;
    messagesChannel: 'sms' | 'whatsapp';
  }
}

export default function useSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [messagesChannel, setMessagesChannel] = useState("whatsapp");

  const { apiCall } = useApiCall();

  const getSettings = useCallback(async () => {
    await apiCall({
      apiToCall: settingsService.getSettings,
      actionAfterResponse: (apiResponse: ISettingsApiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível carregar as configurações. Por favor, tente novamente.`);
          setHasError(true);
          return;
        }
        const { settings } = apiResponse;
        setMessagesChannel(settings.messagesChannel);
        setHasError(false);
      },
      catchMessage: 'Não foi possível carregar as configurações atuais',
      catchAction: () => setHasError(true),
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
    })
  }, [apiCall]);

  const handleSubmit = useCallback(async () => {
    await apiCall({
      apiToCall: settingsService.updateSettings,
      reqBody: JSON.stringify({ messagesChannel }),
      actionAfterResponse: async (apiResponse: ISettingsApiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível atualizar as configurações. Por favor, tente novamente.`);
          return;
        }
        toast.success('Configurações atualizadas com sucesso');
        await getSettings();
      },
      catchMessage: 'Não foi possível atualizar as configurações',
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
    })
  }, [apiCall, getSettings, messagesChannel])

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return {
    isLoading,
    setIsLoading,
    messagesChannel,
    setMessagesChannel,
    handleSubmit,
    hasError,
    handleTryAgain: getSettings,
  }
}
