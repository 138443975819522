
import { format } from "date-fns";
import { useCallback } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../hooks/useApiCall";
import booksService from "../../../../services/booksService";
import ExportXlsx from "../../../../utils/ExportXlsx";
import getCurrentDateWithHyphen from "../../../../utils/getCurrentDateWithHyphen";
import { BookType, UsageReportType } from "../../types";

export default function useDownloadExcel({ fullBooks }: { fullBooks: BookType[] }) {
  const handleDownloadExcel = () => {
    const mappedBooks = fullBooks.map((book) => ({
      'Título': book.title,
      'ISBN': book.isbn || '',
      'Autor': book.author,
      'Data de Adição': book.createdAt,
      'Tipo': book.type,
      'Ativo': book.active ? 'Ativo' : 'Inativo',
      'Link Epub': book.linkEpub,
      'Parceiro': book.partner.nome_fantasia,
      'Categoria': book.category.name,
      'Nível': book.level,
    }));

    ExportXlsx({
      data: mappedBooks,
      filename: `Livros cadastrados Kiddlepass ${getCurrentDateWithHyphen()}`,
    })
  };

  const { apiCall } = useApiCall();

  const handleDownloadUsageReportExcel = useCallback(async () => {
    await toast.promise(apiCall({
      apiToCall: booksService.getUsageReport,
      actionAfterResponse: (apiResponse) => {
          const usageReport: UsageReportType[] = apiResponse.usageReport;
          if (!usageReport || !usageReport.length) {
            toast.error(`Não foi possível baixar o relatório de resgate dos livros (${apiResponse.error})`);
            return;
          }
          const mappedReport = usageReport.map((rpt) => ({
            'Usuário': rpt.userName,
            'E-mail': rpt.userEmail,
            'Identificador Usuário Compartilhado Pela Empresa': rpt.sharedUserId,
            'Título': rpt.title,
            'ISBN': rpt.isbn || '',
            'Autor': rpt.author,
            'Data de Resgate': format(new Date(rpt.redeemedDate), 'dd/MM/yyyy'),
          }));

          const currentDate = getCurrentDateWithHyphen();
          ExportXlsx({
            filename: `Relatório de resgate dos livros Kiddlepass - ${currentDate}`,
            data: mappedReport,
          });
      },
      catchMessage: 'Não foi possível baixar o relatório de resgate dos livros'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    })
  }, [apiCall]);

  return { handleDownloadExcel, handleDownloadUsageReportExcel };
}
