/* eslint-disable max-len */
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import ChangeStatusModal from './components/ChangeStatusModal';
import ListHeader from './components/header';
import List from './components/list';
import SearchAtPage from './components/searchAtPage';
import useCompanies from './useCompanies';

export default function Companies() {
  const {
    isLoading,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredCompanies,
    handleTryAgain,
    fullCompanies,
    pagesQuantity,
    currentPage,
    handlePageChange,
    handleStatusChange,
    selectedStatus,
    setChangeStatusModalShow,
    updateCompanyStatus,
    changeStatusModalShow,
    companyChangingStatus,
    handleOpenChangeStatusModal,
    downloadExcel,
    companiesSplited,
  } = useCompanies();

  const hasCompanies = fullCompanies?.length !== 0 && !!fullCompanies && !isLoading;
  const filteredListLength = filteredCompanies?.length ?? 0;
  const anyResultsByFilterSelection = !filteredCompanies && !isLoading && !doesListApiHasError;
  const isListEmpty = !doesListApiHasError && (!isLoading && fullCompanies.length === 0);

  return (
    <>
      <Sidebar active="Companies" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {hasCompanies && (
          <SearchAtPage
            searchTerm={searchTerm}
            onChangeSearchTerm={handleChangeSearchTerm}
            handleStatusChange={handleStatusChange}
            selectedStatus={selectedStatus}
          />
        )}

        <ListHeader
          doesListExists={hasCompanies}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel="empresa"
          pluralLabel="empresas"
          onPageChange={handlePageChange}
          pagesQuantity={pagesQuantity}
          currentPage={currentPage}
          downloadExcel={downloadExcel}
          companiesSplited={companiesSplited}
        />

        {hasCompanies && (
          <List
            filteredList={filteredCompanies}
            onOpenChangeStatusModal={handleOpenChangeStatusModal}
          />
        )}

        {anyResultsByFilterSelection && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Não encontramos nenhum resultado conforme os filtros selecionados.
                Por favor, tente alterar os critérios.
              </>
  )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter a lista das empresas.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
)}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhuma empresa cadastrada, clique no botão
                {' '}
                <strong>Nova Empresa</strong>
                {' '}
                acima para cadastrar seu primeiro!
              </>
)}
          />
        )}

        <ChangeStatusModal
          companyChangingStatus={companyChangingStatus}
          setChangeStatusModalShow={setChangeStatusModalShow}
          updateCompanyStatus={updateCompanyStatus}
          changeStatusModalShow={changeStatusModalShow}
        />
      </Transitions>
    </>
  );
}
