import { Dispatch, SetStateAction } from 'react';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import Pagination from '../../../../components/Pagination';
import { useAppContext } from '../../../../contexts/auth';
import { CupomFromApiObject } from '../../types';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  pagesQuantity: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  downloadExcel: () => void;
  setAddCupomModalShow: Dispatch<SetStateAction<boolean>>,
  cuponsSplited: CupomFromApiObject[][],
}

export default function ListHeader({
  doesListExists,
  hasError,
  // filteredListLength,
  singularLabel,
  pluralLabel,
  pagesQuantity = 0,
  currentPage,
  onPageChange,
  downloadExcel,
  setAddCupomModalShow,
  cuponsSplited,
}: ListHeaderInterface) {
  const { user: { permission } } = useAppContext();
  const total = cuponsSplited?.flat().length || 0;

  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <div className="leftAlignment">
            <strong>
            {total || 0}
              {' '}
              {total === 1 ? singularLabel : pluralLabel}
            </strong>
            <Pagination
              pagesQuantity={pagesQuantity}
              handlePageChange={onPageChange}
              currentPage={currentPage}
            />
            ({(currentPage + 1) * 100 - 99}-{(currentPage + 1) * 100 > total ? total : (currentPage + 1) * 100})
          </div>
        )}
        <div className="actionButtons">
          {doesListExists && (
            <button type="button" onClick={downloadExcel}>Download XLSX</button>
          )}
          {permission !== 'support' && (
            <button type="button" onClick={() => setAddCupomModalShow(true)}>Novo cupom</button>
          )}
        </div>
      </Header>
    </OpacityAnimation>
  );
}
