import Select from 'react-select';
import { CustomStyle } from '../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../components/CustomSelectStyleDarkTheme';

import { ChangeEvent } from 'react';
import FormGroup from '../../../../components/FormGroup';
import Input from '../../../../components/Input';
import MyModal from '../../../../components/Modal';
import useThemeContext from '../../../../contexts/theme';
import isCnpjValid from '../../../../utils/isCnpjValid';
import isCpfvalid from '../../../../utils/isCpfValid';

interface AddModalInterface {
  modalCreateShow: boolean;
  collaboratorId: string;
  handleCollaboratorIdChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleCreateModalClose: () => void;
  addCollaborator: () => void;
  associatedCompanies: string[];
  viewByAssociatedCompanies: boolean;
  handleCompanyFormChange: (company: { value: string | null, label: string }) => void;
  selectedCompanyForm: { value: string | null, label: string }
  authenticationType: string;
}

export default function AddModal({
  modalCreateShow,
  collaboratorId,
  handleCollaboratorIdChange,
  handleCreateModalClose,
  addCollaborator,
  associatedCompanies,
  viewByAssociatedCompanies,
  selectedCompanyForm,
  handleCompanyFormChange,
  authenticationType,
}: AddModalInterface) {
  const { selectedTheme } = useThemeContext();

  const companyOptionsMapped = associatedCompanies.map((cp: string) => ({ value: cp, label: cp }));

  const options = [
    { value: null, label: 'Selecione uma empresa associada'},
    ...companyOptionsMapped,
  ];

  return (
    <MyModal
      show={modalCreateShow}
      title="Adicionar novo beneficiário"
      modalBody={(
        <>

          {viewByAssociatedCompanies && (
            <FormGroup>
               <label htmlFor="selectedCompanyForm">Empresa associada</label>
              <Select
                value={selectedCompanyForm}
                options={options}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                onChange={(company) => handleCompanyFormChange(company!)}
                placeholder="Visão Geral"
                styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
                // menuPortalTarget={document.body}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </FormGroup>
          )}

          <FormGroup>
            <label htmlFor="id">Id do beneficiário</label>
            <Input
              placeholder="Insira o id do beneficiário a ser adicionado"
              value={collaboratorId}
              onChange={handleCollaboratorIdChange}
              autoComplete="new-password"
              darkBorder
              maxLength={authenticationType === 'cpf' ? 18 : 100}
            />
          </FormGroup>
        </>
      )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Adicionar"
      onClose={handleCreateModalClose}
      onAction={() => addCollaborator()}
      isActionButtonDisabled={!collaboratorId || (authenticationType === 'cpf' && (!isCpfvalid(collaboratorId) && !isCnpjValid(collaboratorId)))}
      type="action"
      minHeight='300px'
    />
  );
}
