import { Dispatch, SetStateAction } from 'react';
import MyModal from '../../../components/Modal';

interface DeleteModalInterface {
  cupomBeingDeleted: string;
  cupomId: string;
  setDeleteModalShow: Dispatch<SetStateAction<boolean>>;
  deleteCupom: (cupomId: string, cupomPlanId?: number | null) => Promise<void>;
  deleteModalShow: boolean;
  cupomPlanId?: number | null;
}

export default function DeleteModal({
  cupomBeingDeleted,
  cupomId,
  cupomPlanId,
  setDeleteModalShow,
  deleteCupom,
  deleteModalShow,
}: DeleteModalInterface) {
  return (
    <MyModal
      title={`Excluir cupom ${cupomBeingDeleted}`}
      closeButtonLabel="Cancelar"
      actionButtonLabel="Excluir"
      modalBody={(
        <>
          <div>
            Tem certeza que deseja excluir o cupom
            {' '}
            <strong>{cupomBeingDeleted}</strong>
            {' '}
            ?
          </div>
          <br />
          <strong>Atenção, esta ação é irreversível!</strong>
          <br />
          <strong>Todos os usuários que estiverem utilizando este cupom, perderão a condição que o mesmo concede. Se o plano do cupom for de acesso grátis, ou período grátis sem cobrança, a assinatura desses usuários será desativada.</strong>
        </>
          )}
      onClose={() => setDeleteModalShow(false)}
      onAction={() => deleteCupom(cupomId, cupomPlanId)}
      show={deleteModalShow}
      type="deleteAction"
    />
  );
}
