import { Link } from 'react-router-dom';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import Pagination from '../../../../components/Pagination';
import { BookType } from '../../types';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  pagesQuantity: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  handleDownloadExcel: () => void;
  handleDownloadUsageReportExcel: () => void;
  booksSplited: BookType[][];
}

export default function ListHeader({
  doesListExists,
  hasError,
  // filteredListLength,
  singularLabel,
  pluralLabel,
  pagesQuantity,
  currentPage,
  onPageChange,
  handleDownloadExcel,
  handleDownloadUsageReportExcel,
  booksSplited,
}: ListHeaderInterface) {
  const total = booksSplited?.flat().length || 0;

  return (
    <>
      <OpacityAnimation delay={0.1}>
        <Header doesListExists={doesListExists} apiFetched={!hasError}>
          {(doesListExists) && (
            <div className="leftAlignment">
              <strong>
              {total || 0}
              {' '}
              {total === 1 ? singularLabel : pluralLabel}
              </strong>
              <Pagination
                pagesQuantity={pagesQuantity}
                handlePageChange={onPageChange}
                currentPage={currentPage}
              />
              ({(currentPage + 1) * 100 - 99}-{(currentPage + 1) * 100 > total ? total : (currentPage + 1) * 100})
            </div>
          )}
          <div className="actionButtons">
            <Link to="/books/categories">
              Gerenciar categorias
            </Link>
            <Link to="/books/new">
              Novo livro
            </Link>
            {doesListExists && (
              <>
              <button type="button" onClick={handleDownloadExcel}>
                Download XLSX
              </button>
              <button type="button" onClick={handleDownloadUsageReportExcel}>
                Download Relatório de uso por usuário
              </button>
              </>
            )}
          </div>
        </Header>
      </OpacityAnimation>
    </>
  );
}
