import { Col, Container, Row } from "react-bootstrap";
import Button from '../../components/Button';
import FilterRadioButton from "../../components/FilterRadioButtons";
import { FilterRadioButtonsContainer } from "../../components/FilterRadioButtonsContainer";
import { ButtonContainer, Form } from "../../components/Form";
import FormGroup from "../../components/FormGroup";
import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import PageHeader from "../../components/PageHeader";
import Sidebar from "../../components/Sidebar";
import Transitions from "../../components/Transition";
import useSettings from "./useSettings";

export default function Settings() {
  const {
    isLoading,
    messagesChannel,
    setMessagesChannel,
    handleSubmit,
    hasError,
    handleTryAgain,
  } = useSettings();

  return (
    <>
      <Sidebar active="Settings" />
      <Loader isLoading={isLoading} />
      <Transitions>
        <PageHeader title="Configurações App" />
        {!isLoading && !hasError && (
          <Form noValidate autoComplete="new-password" onSubmit={(e) => e.preventDefault()}>
            <Container>
              <Row xs={1} md={1} lg={1}>
                <Col>
                  <FormGroup>
                    <label htmlFor="currentPassword">Canal para envio de mensagens do fluxo de cadastro</label>
                    <FilterRadioButtonsContainer>
                      <FilterRadioButton selected={messagesChannel === 'whatsapp'} onClick={() => setMessagesChannel('whatsapp')}>
                        WhatsApp
                      </FilterRadioButton>
                      <FilterRadioButton selected={messagesChannel === 'sms'} onClick={() => setMessagesChannel('sms')}>
                        SMS
                      </FilterRadioButton>
                    </FilterRadioButtonsContainer>
                  </FormGroup>
                </Col>
              </Row>
            </Container>

            <ButtonContainer>
              <Button type="button" onClick={handleSubmit}>
                Confirmar
              </Button>
            </ButtonContainer>
          </Form>
        )}

        {!isLoading && hasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter as configurações.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
              </>
            )}
          />
        )}
      </Transitions>
    </>
  )
}
