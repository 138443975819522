import OpacityAnimation from '../../../../components/OpacityAnimation';
import Pagination from '../../../../components/Pagination';
import { MappedSubscriber } from '../../types';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  pagesQuantity: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  downloadExcel: () => void;
  subscribersSplited: Array<MappedSubscriber[]>;
}

export default function ListHeader({
  doesListExists,
  hasError,
  // filteredListLength,
  singularLabel,
  pluralLabel,
  pagesQuantity,
  currentPage,
  onPageChange,
  downloadExcel,
  subscribersSplited,
}: ListHeaderInterface) {
  const total = subscribersSplited?.flat().length || 0;
  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <>
            <div className="leftAlignment">
            <strong>
              {total || 0}
              {' '}
              {total === 1 ? singularLabel : pluralLabel}
            </strong>
              <Pagination
                pagesQuantity={pagesQuantity}
                handlePageChange={onPageChange}
                currentPage={currentPage}
              />
               ({(currentPage + 1) * 100 - 99}-{(currentPage + 1) * 100 > total ? total : (currentPage + 1) * 100})
            </div>
            <div className="actionButtons">
              <button type="button" onClick={downloadExcel}>
                Download XLSX
              </button>
            </div>
          </>
        )}
      </Header>
    </OpacityAnimation>
  );
}
