import { format } from 'date-fns';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import activate from '../../../../assets/images/icons/activate.svg';
import edit from '../../../../assets/images/icons/edit.svg';
import suspend from '../../../../assets/images/icons/suspend.svg';
import trash from '../../../../assets/images/icons/trash.svg';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { BookType } from '../../types';
import {
  Card,
} from './styles';

interface HandleOpenChangeStatusModalInterface {
  status: string;
  name: string;
  id: string;
}

interface HandleOpenDeleteModalInterface {
  name: string;
  id: string;
}

interface ListInterface {
  filteredList: BookType[];
  handleOpenDeleteModal: ({ name, id }: HandleOpenDeleteModalInterface) => void;
  handleOpenChangeStatusModal: ({ name, id, status }: HandleOpenChangeStatusModalInterface) => void;
}

export default function List({
  filteredList,
  handleOpenDeleteModal,
  handleOpenChangeStatusModal,
}: ListInterface) {
  console.log(filteredList)
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((book) => (
          <Col key={book.id}>
            <OpacityAnimation delay={0.1}>
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{book.title}</strong>
                    <small className={book.active ? '' : 'orange'}>
                      {book.active ? 'Ativo' : 'Inativo'}
                    </small>
                  </div>
                  <span>
                    {book.isRedeemable ? 'Resgatável' : 'Não Resgatável'}
                  </span>
                  <span>
                    Categoria:
                    {' '}
                    {book.category.name}
                  </span>
                  <span>
                    Parceiro:
                    {' '}
                    {book.partner.nome_fantasia}
                  </span>
                  <span>
                    Tipo:
                    {' '}
                    {book.type === 'external' ? 'Externo' : 'Interno'}
                  </span>
                  <span>
                    Adicionado em
                    {' '}
                    {book.createdAt ? format(new Date(book.createdAt), 'dd/MM/yyyy') : 'Não informado'}
                  </span>
                </div>

                <div className="actions">
                  <button
                    type="button"
                    onClick={() => handleOpenChangeStatusModal({
                      name: book.title,
                      id: book.id,
                      status: book.active ? 'Ativo' : 'Inativo',
                    })}
                  >
                    {book.active ? (
                      <img src={suspend} alt="suspend" title="Inativar Livro" />
                    ) : (
                      <img src={activate} alt="trash" title="Reativar Livro" />
                    )}
                  </button>

                  <Link to={`/books/${book.id}`}>
                    <img src={edit} alt="edit" title="Editar livro" className="primaryColor" />
                  </Link>

                {!book.isRedeemable && (
                  <button
                    type="button"
                    onClick={() => handleOpenDeleteModal({
                      name: book.title,
                      id: book.id,
                    })}
                  >
                    <img src={trash} alt="trash" title="Excluir Livro" />
                  </button>
                )}
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
