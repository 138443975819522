/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../hooks/useApiCall';
import companiesService from '../../../../../services/companiesService';
import ExportXlsx from '../../../../../utils/ExportXlsx';

type CollaboratorType = {
  id: string;
  empresa_suspenso?: string | null;
  data_ativacao?: string | null;
  associated_company?: string;
  createdAt?: string;
  updatedAt?: string;
}

type UsersFromApiResponse = {
  result?: CollaboratorType[];
  associatedCompanies?: { name: string, createdAt: Date }[];
  error?: string;
  createdAt?: string;
  updatedAt?: string;
}

export default function useDownloadUsers() {
  const [downloadModalShow, setDownloadModalShow] = useState(false);
  const [selectedFilterDownload, setSelectedFilterDownload] = useState('');

  const { apiCall } = useApiCall();
  const navigate = useNavigate();

  const { companyId } = useParams();

  const today = new Date().toJSON().slice(0, 10);
  const day = today.split('-')[2];
  const month = today.split('-')[1];
  const year = today.split('-')[0];
  const currentDate = `${day}-${month}-${year}`;

  const downloadUsers = useCallback(async () => {
    if (!companyId) {
      toast.error('Não identificamos a empresa através da URL. Por favor, tente novamente.');
      navigate('/companies');
      return;
    }

    await toast.promise(apiCall({
      apiToCall: companiesService.listUsers,
      queryParams: { companyId },
      onStartLoad: () => setDownloadModalShow(false),
      actionAfterResponse: (apiResponse: UsersFromApiResponse) => {
        if (apiResponse.error && apiResponse.error !== 'sem usuarios' && !apiResponse.result) {
          toast.error(`Não foi possível baixar o xlsx dos colaboradores (${apiResponse.error})`);
          return;
        }

        const mappedAllUsers = apiResponse.result!.map((collaborator) => ({
          id: collaborator.id,
          dataAtivacao: collaborator.data_ativacao || 'Nunca ativado',
          // eslint-disable-next-line no-nested-ternary
          status: (collaborator.empresa_suspenso
            ? 'Suspenso'
            : (
              collaborator.data_ativacao
                ? 'Ativo'
                : 'Registrado'
            )),
          // eslint-disable-next-line no-nested-ternary
          codStatus: (collaborator.empresa_suspenso
            ? 2
            : (
              collaborator.data_ativacao
                ? 1
                : 3
            )),
          associatedCompany: collaborator.associated_company ?? 'Não identificada',
          createdAt: collaborator.createdAt ? format(new Date(collaborator.createdAt), 'dd/MM/yyyy') : '',
          updatedAt: collaborator.updatedAt ? format(new Date(collaborator.updatedAt), 'dd/MM/yyyy') : '',
        }));

        if (selectedFilterDownload === 'todos') {
          const dataToBeDownloaded = mappedAllUsers.map((collaborator) => ({
            id: collaborator.id,
            'Data de ativação': collaborator.dataAtivacao || 'Nunca ativado',
            status: collaborator.status,
            'Empresa Associada': collaborator.associatedCompany,
            'Data de criação': collaborator.createdAt || 'Não informado',
            'Data de atualização': collaborator.updatedAt || 'Não informado',
          }));
          ExportXlsx({
            data: dataToBeDownloaded,
            filename: `Colaboradores benefício kiddlepass com status TODOS ${currentDate}`,
          });
        }

        if (selectedFilterDownload === 'ativos') {
          const onlyAtivos = mappedAllUsers.filter((collaborator) => (
            collaborator.status === 'Ativo'
          ));
          const dataToBeDownloaded = onlyAtivos.map((collaborator) => ({
            id: collaborator.id,
            'Data de ativação': collaborator.dataAtivacao || 'Nunca ativado',
            status: collaborator.status,
            'Empresa Associada': collaborator.associatedCompany,
            'Data de criação': collaborator.createdAt || 'Não informado',
            'Data de atualização': collaborator.updatedAt || 'Não informado',
          }));
          ExportXlsx({
            data: dataToBeDownloaded,
            filename: `Colaboradores benefício kiddlepass com status ATIVOS ${currentDate}`,
          });
        }

        if (selectedFilterDownload === 'suspensos') {
          const onlySuspensos = mappedAllUsers.filter((collaborator) => (
            collaborator.status === 'Suspenso'
          ));
          const dataToBeDownloaded = onlySuspensos.map((collaborator) => ({
            id: collaborator.id,
            'Data de ativação': collaborator.dataAtivacao || 'Nunca ativado',
            status: collaborator.status,
            'Empresa Associada': collaborator.associatedCompany,
            'Data de criação': collaborator.createdAt || 'Não informado',
            'Data de atualização': collaborator.updatedAt || 'Não informado',
          }));
          ExportXlsx({
            data: dataToBeDownloaded,
            filename: `Colaboradores benefício kiddlepass com status SUSPENSOS ${currentDate}`,
          });
        }

        if (selectedFilterDownload === 'registrados') {
          const onlyRegistrados = mappedAllUsers.filter((collaborator) => (
            collaborator.status === 'Registrado'
          ));
          const dataToBeDownloaded = onlyRegistrados.map((collaborator) => ({
            id: collaborator.id,
            'Data de ativação': collaborator.dataAtivacao || 'Nunca ativado',
            status: collaborator.status,
            'Empresa Associada': collaborator.associatedCompany,
            'Data de criação': collaborator.createdAt || 'Não informado',
            'Data de atualização': collaborator.updatedAt || 'Não informado',
          }));
          ExportXlsx({
            data: dataToBeDownloaded,
            filename: `Colaboradores benefício kiddlepass com status REGISTRADOS ${currentDate}`,
          });
        }
      },
      catchMessage: 'Não foi possível baixar a planilha de colaboradores'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    });
  }, [companyId, apiCall, navigate, selectedFilterDownload, currentDate]);

  return {
    downloadModalShow,
    setDownloadModalShow,
    selectedFilterDownload,
    setSelectedFilterDownload,
    downloadUsers,
  };
}
