import { ChangeEvent, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../hooks/useApiCall';
import companiesService from '../../../../../services/companiesService';
import formatCnpj from '../../../../../utils/formatCnpj';
import formatCpf from '../../../../../utils/formatCpf';
import isCnpjValid from '../../../../../utils/isCnpjValid';
import isCpfvalid from '../../../../../utils/isCpfValid';

interface UseAddCollaboratorInterface {
  loadUsers: (currentPage: number) => void;
  currentPage: number;
  authenticationType: string;
}

export default function useAddCollaborator({
  loadUsers,
  currentPage,
  authenticationType,
}: UseAddCollaboratorInterface) {
  const [isAdding, setIsAdding] = useState(false);
  const [modalCreateShow, setModalCreateShow] = useState(false);
  const [collaboratorId, setCollaboratorId] = useState('');
  const [selectedCompanyForm, setSelectedCompanyForm] = useState<{ value: string | null, label: string }>({ value: null, label: 'Selecione uma empresa associada' });

  const { apiCall } = useApiCall();
  const navigate = useNavigate();

  const { companyId } = useParams();
  const isCpf = authenticationType === 'cpf';

  function handleCollaboratorIdChange(event: ChangeEvent<HTMLInputElement>) {
    if (isCpf) {
      const formattedCpf = formatCpf(event.target.value);
      setCollaboratorId(formattedCpf);

      const hasMoreThanFourteenDigits = formattedCpf.length > 14;
      if (hasMoreThanFourteenDigits) {
        const formattedCnpj = formatCnpj(event.target.value);
        setCollaboratorId(formattedCnpj);
      }

      return
    }
    setCollaboratorId(event.target.value);
  }

  function handleCreateModalShow() {
    setModalCreateShow(true);
  }

  function handleCreateModalClose() {
    setModalCreateShow(false);
  }

  function handleCompanyFormChange(company: { value: string | null, label: string }) {
    setSelectedCompanyForm(company);
  }

  const addCollaborator = useCallback(async () => {
    if (!companyId) {
      toast.error('Não identificamos a empresa através da URL. Por favor, tente novamente.');
      navigate('/companies');
      return;
    }

    if (isCpf) {
      if (!isCpfvalid(collaboratorId) && !isCnpjValid(collaboratorId)) {
        toast.error('CPF inválido');
        return;
      }
    }

    await apiCall({
      apiToCall: companiesService.createMultiUsers,
      queryParams: { companyId },
      reqBody: JSON.stringify({
        list: [{ id: String(collaboratorId).toLowerCase(), associated_company: selectedCompanyForm.value }],
      }),
      onStartLoad: () => {
        setModalCreateShow(false);
        setIsAdding(true);
      },
      onEndLoad: () => {
        setIsAdding(false);
        setCollaboratorId('');
      },
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          setIsAdding(false);
          toast.error('Não foi possível adicionar o colaborador');
          return;
        }
        loadUsers(currentPage);
        toast.success('Colaborador adicionado com sucesso');
      },
      catchMessage: 'Não foi possível adicionar o colaborador'
    });
  }, [companyId, isCpf, apiCall, collaboratorId, selectedCompanyForm.value, navigate, loadUsers, currentPage]);

  return {
    isAdding,
    modalCreateShow,
    setModalCreateShow,
    collaboratorId,
    handleCollaboratorIdChange,
    addCollaborator,
    handleCreateModalShow,
    handleCreateModalClose,
    handleCompanyFormChange,
    selectedCompanyForm,
  };
}
