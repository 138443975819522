import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import FilterRadioButton from '../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../components/FilterRadioButtonsContainer';
import MyModal from '../../../../components/Modal';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import Pagination from '../../../../components/Pagination';
import { MappedActivitiesListType } from '../../types';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  pagesQuantity: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  downloadExcel: () => void;
  downloadRatingsExcel: () => Promise<void>;
  downloadVideoViewsExcel: () => Promise<void>;
  activitiesSplited: Array<MappedActivitiesListType[]>
}

export default function ListHeader({
  doesListExists,
  hasError,
  // filteredListLength,
  singularLabel,
  pluralLabel,
  pagesQuantity,
  currentPage,
  onPageChange,
  downloadExcel,
  downloadRatingsExcel,
  downloadVideoViewsExcel,
  activitiesSplited,
}: ListHeaderInterface) {
  const [relatoryDownloadsModalShowm, setRelatoryDownloadsModalShowm] = useState(false);
  const [selectedRelatory, setSelectedRelatory] = useState<'ratings' | 'views'>('ratings');

  const downloadRelatory = useCallback(async () => {
    if (selectedRelatory === 'ratings') {
      await downloadRatingsExcel();
    } else {
      await downloadVideoViewsExcel();
    }
    setRelatoryDownloadsModalShowm(false);
  }, [downloadRatingsExcel, downloadVideoViewsExcel, selectedRelatory])

  const total = activitiesSplited?.flat().length || 0;

  return (
    <>
      <OpacityAnimation delay={0.1}>
        <Header doesListExists={doesListExists} apiFetched={!hasError}>
          {(doesListExists) && (
            <div className="leftAlignment">
              <strong>
              {total || 0}
              {' '}
              {total === 1 ? singularLabel : pluralLabel}
              </strong>
              <Pagination
                pagesQuantity={pagesQuantity}
                handlePageChange={onPageChange}
                currentPage={currentPage}
              />
              ({(currentPage + 1) * 100 - 99}-{(currentPage + 1) * 100 > total ? total : (currentPage + 1) * 100})
            </div>
          )}
          <div className="actionButtons">
            <Link to="/activities/categories">
              Gerenciar categorias
            </Link>
            {doesListExists && (
              <>
              <button type="button" onClick={downloadExcel}>
                Download XLSX
              </button>
              <button type="button" onClick={() => setRelatoryDownloadsModalShowm(true)}>
                Download XLSX (Relatórios)
              </button>
              </>
            )}
            <Link to="/activities/new">
              Nova atividade
            </Link>
          </div>
        </Header>
      </OpacityAnimation>

      <MyModal
        show={relatoryDownloadsModalShowm}
        title={'Selecione qual relatório deseja baixar'}
        closeButtonLabel="Fechar"
        actionButtonLabel={'Confirmar'}
        onClose={() => setRelatoryDownloadsModalShowm(false)}
        onAction={() => downloadRelatory()}
        type="action"
        modalBody={(
          <FilterRadioButtonsContainer>
            <FilterRadioButton selected={selectedRelatory === 'ratings'} onClick={() => setSelectedRelatory('ratings')}>
              Relatório de Avaliações
            </FilterRadioButton>
            <FilterRadioButton selected={selectedRelatory === 'views'} onClick={() => setSelectedRelatory('views')}>
              Relatório de Visualizações dos vídeos
            </FilterRadioButton>
          </FilterRadioButtonsContainer>
        )}
      />
    </>
  );
}
