import { useMemo, useState } from 'react';
import { MappedSubscriber } from '../../types';

export default function usePagination() {
  const [filteredSubscribers, setFilteredSubscribers] = useState<MappedSubscriber[]>([]);
  const [subscribersSplited, setSubscribersSplited] = useState<Array<MappedSubscriber[]>>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const pagesQuantity = useMemo(() => subscribersSplited.length, [subscribersSplited.length]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
    setFilteredSubscribers(subscribersSplited[page]);
  }

  return {
    filteredSubscribers,
    setFilteredSubscribers,
    setSubscribersSplited,
    currentPage,
    setCurrentPage,
    pagesQuantity,
    handlePageChange,
    subscribersSplited,
  };
}
