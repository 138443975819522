import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import edit from '../../../../assets/images/icons/edit.svg';
import halfStar from '../../../../assets/images/icons/half-star.svg';
import trash from '../../../../assets/images/icons/trash.svg';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { useAppContext } from '../../../../contexts/auth';
import dateWith0 from '../../../../utils/dateWith0';
import renderArray from '../../../../utils/renderArrayWithComa';
import { MappedUserType, RatingType, UserBeingDeletedType } from '../../types';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: MappedUserType[];
  onOpenDeleteModal: ({ id, name }: UserBeingDeletedType) => void;
  downloadRatings: (ratingsArray: RatingType[], userName: string) => void;
}

export default function List({
  filteredList,
  onOpenDeleteModal,
  downloadRatings,
}: ListInterface) {
  const smallTagColorByStatusLiterals: { [key: string]: string } = {
    Ativa: 'green',
    Cancelada: 'orange',
    Teste: '',
    Cadastrado: '',
    Suspensa: 'gray',
  };

  const appContext = useAppContext();
  const { permission } = appContext.user;

  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((user) => (
          <Col key={user.id}>
            <OpacityAnimation delay={0.1}>
              <Card title="Expandir detalhes">
                <div className="info">
                  <div className="card-title">
                    <strong>{user.name}</strong>
                    <small className={smallTagColorByStatusLiterals[user.status]}>{user.status}</small>
                  </div>
                  <span>
                    CPF:
                    {' '}
                    {user.cpf || 'Não informado'}
                  </span>
                  <span>
                    Celular:
                    {' '}
                    {user.cellphone || 'Não informado'}
                  </span>
                  <span>
                    Cadastrado em
                    {' '}
                    {dateWith0(user.registrationDate.split(' ')[0])}
                  </span>
                  <span>
                    Empresa:
                    {' '}
                    {user.company || 'B2C'}
                  </span>
                  <span>
                    Empresas anteriores:
                    {' '}
                    {(user.pastCompanies && user.pastCompanies.length > 0) ? renderArray(user.pastCompanies) : 'Nenhuma'}
                  </span>
                </div>
                <div className="actions">
                  <Link to={`/users/${user.id}`}>
                    <img src={edit} alt="" title={`Editar parceiro ${user.name}`} className="primaryColor" />
                  </Link>
                  {user.ratings && user.ratings?.length > 0 && (
                    <button
                      type="button"
                      onClick={() => user.ratings ? downloadRatings(user.ratings, user.name) : null}
                    >
                    <img src={halfStar} alt="" title={`Baixar XLSX das notas dadas por ${user.name}`} />
                  </button>
                  )}
                  {(!user.status.includes('Assinante') && permission === 'super') && (
                    <button
                      type="button"
                      onClick={() => onOpenDeleteModal({
                        id: user.id,
                        name: user.name,
                      })}
                    >
                      <img src={trash} alt="" title={`Remover usuário ${user.name}`} />
                    </button>
                  )}
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
