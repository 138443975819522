import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import DeleteModal from './components/DeleteModal';
import Filters from './components/Filters';
import UpdateStatusModal from './components/UpdateStatusModal';
import ListHeader from './components/header';
import List from './components/list';
import SearchAtPage from './components/searchAtPage';
import useActivities from './useActivities';

export default function Activities() {
  const {
    isLoading,
    fullActivities,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredActivities,
    handleTryAgain,
    pagesQuantity,
    currentPage,
    categoriesList,
    selectedCategory,
    subcategoriesOptions,
    selectedSubcategory,
    partnersList,
    selectedPartner,
    selectedStatus,
    selectedAgeRange,
    handlePartnerChange,
    handleCategoryChange,
    handleSubcategoryChange,
    handleStatusChange,
    handlePageChange,
    setSelectedAgeRange,
    handleOpenDeleteModal,
    deleteModalShow,
    setDeleteModalShow,
    activityBeingDeleted,
    deleteActivity,
    handleOpenChangeStatusModal,
    changeStatusModalShow,
    setChangeStatusModalShow,
    activityChangingStatus,
    updateActivityStatus,
    downloadExcel,
    handleAgeSelection,
    downloadRatings,
    downloadRatingsExcel,
    handleKindChange,
    selectedKind,
    downloadVideoViewsExcel,
    activitiesSplited,
  } = useActivities();

  const hasActivities = fullActivities?.length !== 0 && !!fullActivities && !isLoading;
  const filteredListLength = filteredActivities?.length ?? 0;
  const anyResultsByFilterSelection = !filteredActivities && !isLoading;
  const isListEmpty = !doesListApiHasError && (!isLoading && fullActivities.length === 0);

  return (
    <>
      <Sidebar active="Activities" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {hasActivities && !isLoading && (
          <>
            <Filters
              categoriesList={categoriesList}
              selectedCategory={selectedCategory}
              subcategoriesOptions={subcategoriesOptions}
              selectedSubcategory={selectedSubcategory}
              partnersList={partnersList}
              selectedPartner={selectedPartner}
              selectedStatus={selectedStatus}
              handleStatusChange={handleStatusChange}
              selectedAgeRange={selectedAgeRange}
              setSelectedAgeRange={setSelectedAgeRange}
              handlePartnerChange={handlePartnerChange}
              handleCategoryChange={handleCategoryChange}
              handleSubcategoryChange={handleSubcategoryChange}
              handleAgeSelection={handleAgeSelection}
              handleKindChange={handleKindChange}
              selectedKind={selectedKind}
            />

            <SearchAtPage
              searchTerm={searchTerm}
              onChangeSearchTerm={handleChangeSearchTerm}
            />
          </>
        )}

        {!isLoading && (
          <ListHeader
            doesListExists={hasActivities}
            hasError={doesListApiHasError}
            filteredListLength={filteredListLength}
            singularLabel="atividade"
            pluralLabel="atividades"
            onPageChange={handlePageChange}
            pagesQuantity={pagesQuantity}
            currentPage={currentPage}
            downloadExcel={downloadExcel}
            downloadRatingsExcel={downloadRatingsExcel}
            downloadVideoViewsExcel={downloadVideoViewsExcel}
            activitiesSplited={activitiesSplited}
          />
        )}

        {hasActivities && !isLoading && (
          <List
            filteredList={filteredActivities}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenChangeStatusModal={handleOpenChangeStatusModal}
            downloadRatings={downloadRatings}
          />
        )}

        <DeleteModal
          activityBeingDeleted={activityBeingDeleted}
          setDeleteModalShow={setDeleteModalShow}
          deleteActivity={deleteActivity}
          deleteModalShow={deleteModalShow}
        />

        <UpdateStatusModal
          activityChangingStatus={activityChangingStatus}
          setChangeStatusModalShow={setChangeStatusModalShow}
          updateActivityStatus={updateActivityStatus}
          changeStatusModalShow={changeStatusModalShow}
        />

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter as atividades.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
)}
          />
        )}

        {anyResultsByFilterSelection && !doesListApiHasError && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Não encontramos nenhum resultado conforme os filtros selecionados.
                Por favor, tente alterar os critérios.
              </>
  )}
          />
        )}

        {isListEmpty && !isLoading && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhuma atividade cadastrada, clique no botão
                {' '}
                <strong>Nova Atividade</strong>
                {' '}
                acima para cadastrar sua(s) primeira(s)!
              </>
)}
          />
        )}
      </Transitions>
    </>
  );
}
