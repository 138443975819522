import { Link } from 'react-router-dom';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import Pagination from '../../../../components/Pagination';
import { useAppContext } from '../../../../contexts/auth';
import { MappedCompany } from '../../types';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  pagesQuantity: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  downloadExcel: () => void;
  companiesSplited: MappedCompany[][],
}

export default function ListHeader({
  doesListExists,
  hasError,
  // filteredListLength,
  singularLabel,
  pluralLabel,
  pagesQuantity = 0,
  currentPage,
  onPageChange,
  downloadExcel,
  companiesSplited,
}: ListHeaderInterface) {
  const { user: { permission } } = useAppContext();
  const total = companiesSplited?.flat().length || 0;

  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <div className="leftAlignment">
            <strong>
              {total || 0}
              {' '}
              {total === 1 ? singularLabel : pluralLabel}
            </strong>
            <Pagination
              pagesQuantity={pagesQuantity}
              handlePageChange={onPageChange}
              currentPage={currentPage}
            />
            ({(currentPage + 1) * 100 - 99}-{(currentPage + 1) * 100 > total ? total : (currentPage + 1) * 100})
          </div>
        )}
        {permission !== 'support' && (
          <div className="actionButtons">
            {doesListExists && (
              <button type="button" onClick={downloadExcel}>Download XLSX</button>
            )}
            <Link to="/companies/new">
              Nova Empresa
            </Link>
          </div>
        )}
      </Header>
    </OpacityAnimation>
  );
}
